.cart-table {
  .ant-table-thead {
    > tr {
      th {
        background-color: white;
      }

      .order-details-col {
        /* font-size: 20px; */
      }
    }
  }

  .order-details-info {
    float: left;
    width: 100%;

    .order-main-title {
      font-size: 17px;
    }

    .order-sub-title,
    .order-sub-sub-title {
      font-size: 14px;
      color: #888;
    }
  }

  .cart-price-block {
    position: relative;
    /* padding-top: 20px; */

    .cart-price {
      text-align: right;
    }

    .cart-price-conversion {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: var(--bs-secondary);
      font-size: 11px;
      color: white;
      line-height: 1;
      padding: 2px 4px;
      border-radius: 3px;
    }
  }

  .cart-price-number,
  .cart-amount {
    /* font-size: 18px; */
    font-size: 17px;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;

    svg {
      font-size: 16px;
    }
  }

  .cart-actions {
    white-space: nowrap;

    .ant-btn {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      font-size: 18px;
      color: white;
      background-color: white;

      &.edit-btn {
        border: 1px solid var(--bs-orange);
        color: var(--bs-orange);
      }

      &.delete-btn {
        border: 1px solid var(--bs-danger);
        color: var(--bs-danger);
      }
    }
  }

  tr[data-row-key='cartFooter'] {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--bs-yellow);
      z-index: 1;
      display: block;
      opacity: 0.3;
    }

    td {
      position: relative;
      z-index: 4;
    }
  }
}

.pay-now-btn {
  font-size: 16px;
  background-color: var(--bs-primary);
  color: white;
  min-width: 120px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 20px;
  border-radius: 3px;
}

.ant-col {
  #payment-form {
    /* background-color: #efefef;
    border: 1px solid #ddd; */
    padding: 20px 0;
    #submit {
      @extend .pay-now-btn;
    }
    #payment-stripeMessage {
      margin-top: 10px;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      padding: 10px 20px;
    }
  }
}


.fs-20 {
  font-size: 20px;
}