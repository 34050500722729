.server-config-section {
  border: 1px solid var(--bs-gray-200);

  .server-config-section-title {
    padding: 17px 20px;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    /* background-color: var(--bs-gray-200); */
  }

  .ant-radio-group,
  .ant-space-vertical {
    float: left;
    width: 100%;
    gap: 0 !important;
  }

  .ant-space-item {
    border-bottom: 1px solid var(--bs-gray-300);

    &:last-child {
      border-bottom-color: transparent;
    }
  }

  .ant-radio {
    float: left;
    width: 16px;
    display: block;
  }

  .ant-radio + span {
    float: left;
    width: calc(100% - 16px);
  }

  .selectRadioBtn {
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .config-radio-label {
    padding-left: 20px;
  }

  .ant-radio-wrapper-checked {
    background-color: rgb(239, 255, 239);
  }

  .select-server {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .config-server-details,
  .config-location-title {
    float: left;
    width: calc(100% - 200px);
    padding-right: 20px;
  }

  .config-price-block,
  .config-location-price {
    float: left;
    width: 200px;
    padding-left: 20px;
  }

  .config-price-text {
    font-weight: 700;
    text-align: right;
    font-size: 18px;
    color: #ca0606;

    span {
      font-weight: normal;
      color: var(--bs-gray-900);
      font-size: 15px;
    }
  }

  .config-location-price {
    font-size: 18px;
    color: #ca0606;
    font-weight: 700;
    text-align: right;

    &.free {
      font-weight: normal;
      color: var(--bs-dark);
      /* font-size: 16px; */
    }
  }

  .price-convertion {
    float: right;
    position: absolute;
    right: 2px;
    bottom: 2px;
    font-size: 12px;
    background-color: var(--bs-gray-600);
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    line-height: 1;
  }

  .config-server-title {
    font-weight: 500;
    font-size: 16px;
  }

  .config-server-info {
    font-size: 14px;
    color: #666;
    padding-top: 6px;
  }

  .config-location-title {
    font-size: 18px;
  }
}

.server-configur-helper-info {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  padding: 20px 25px 20px 25px;
}

.final-order-pricing-block {
  position: fixed;
  bottom: 0;
  left: 16.66666667%;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 2px -2px 3px rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  flex-direction: row;
  z-index: 9;

  .final-order-info {
    float: left;
    width: calc(100% - 500px);
  }

  .final-order-actions-block {
    float: left;
    width: 500px;
    text-align: right;

    button {
      margin-left: 10px;
    }
  }

  .order-info-title {
    font-size: 18px;
  }

  .order-misc-info {
    font-size: 13px;
    color: var(--bs-info);
  }
}

.order-basic-info-list {
  padding-bottom: 7px;
}

.server-config-section-row {
  background-color: white;
}

.qty-block {
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 20px;
  top: -7px;

  .quatityTextInput {
    background-color: white;
    position: relative;
    z-index: 4;
    width: 60px;

    input {
      height: 40px;
      text-align: center;
      font-size: 20px;
    }
  }

  .qty-toggle-btn {
    width: 32px;
    height: 42px;
    font-size: 20px;
    line-height: 1;
    padding: 0;
    background-color: white;
    color: var(--bs-gray-800);
    z-index: 2;

    &.qty-add-btn {
      margin-left: -1px;
    }

    &.qty-minus-btn {
      margin-right: -1px;
    }

    &:disabled,
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.selected-slots-list {
  li {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
}

.each-selected-slot {
  font-size: 14px;

  .each-selected-slot-header {
    background-color: var(--bs-gray-700);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 10px;
    line-height: 1.1;

    .selected-slot-number {
      float: left;
      width: 50%;
    }
  }

  .slot-number,
  .slot-form-factor {
    float: left;
    width: 25%;
  }

  .slot-title {
    width: 50%;
    float: left;
  }

  .each-selected-slot-info-block {
    background-color: white;
    padding: 10px;
  }

  .each-selected-slot-info {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-400);
    padding: 15px;
    color: var(--bs-dark);
    font-size: 16px;
  }
}

.unselected {
  .each-selected-slot-header {
    background-color: var(--bs-gray-800);
    color: var(--bs-gray-800);
    border-bottom: 1px solid #ddd;
  }

  .slot-title {
    color: var(--bs-gray-600);
    font-size: 13px;
  }
}

.slot-selecting-btn {
  font-size: 14px;
  padding: 5px 10px;
  color: var(--bs-gray-800);
  line-height: 1.1;
  height: auto;

  svg {
    font-size: 13px;
    margin-right: 8px;
    color: #888;
  }

  &:hover {
    color: var(--bs-gray-800);
  }
}


.selected-drive-list {
  border-top: 10px solid #bbb;

  .selected-drive-header {
    font-size: 18px;
    color: var(--bs-dark);
    padding: 10px;
  }


}

.selected-table-grid {
  li {
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
}

.selected-table-row {
  display: grid;
  grid-template-columns: 40% 35% 15% 10%;
  font-size: 14px;

  &.grid-header {
    font-weight: 700;
  }

  .selected-drive-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 11px;
      height: 11px;
      float: left;
      display: block;
      background-color: var(--bs-gray-500);
      margin-right: 10px;
      border-radius: 2px;
    }
  }

  .selected-filesystem {
    padding-left: 10px;
    padding-right: 10px;
  }

  .selected-add-btn {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 15px;

    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.bandwidth-grid {

}