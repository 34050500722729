.customer-details-list {
  li {
    margin-bottom: 7px;
    float: left;
    width: 100%;
  }
}

.each-customer-detail {
  font-size: 14px;
  .each-customer-title {
    float: left;
    width: 150px;
    color: #888;
  }

  .each-customer-details {
    float: left;
    width: calc(100% - 150px);
    color: #111;
  }
}

.order-details-summary {
  .brand-name {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 20px 20px 15px 20px;

    span {
      border-bottom: 1px solid #ddd;
      display: inline-block;
      padding-bottom: 5px;
    }
  }

  .order-price {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
}

.order-detail-table {
  border: 1px solid #eee;
  font-size: 15px;

  th,
  td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  tr {
     &:nth-child(even) {
        td {
           background-color: #f9f9f9;
        }
     }
  }
}
